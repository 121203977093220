var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g_page_box"},[_c('div',{staticClass:"g_main_content main_content"},[_c('g-loading',{attrs:{"loading":_vm.loading}}),(_vm.state === '1')?_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/img/pay_success.png"),"width":"153"}}):_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/img/pay_cancel.png"),"width":"153"}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.state === '1' ? '支付成功':'支付失败'))]),_c('div',{staticClass:"surplus_time"},[_vm._v(_vm._s(_vm.state === '1'? _vm.type==='2'?'请点击查看订单进行预约':_vm.type==='6'?'请点击预约肠镜':'请点击查看挂号列表':'请重新下单'))])],1),_c('div',{staticClass:"bottom_box"},[_c('router-link',{attrs:{"to":{
      path: '/my-check-detail',
      query: {
        order_id: _vm.reset_query(_vm.id)
      }
    }}},[(_vm.state === '1' && _vm.type === '2')?_c('div',{staticClass:"btn"},[_vm._v("预约检查")]):_vm._e()]),_c('router-link',{attrs:{"to":{
      path: '/my-check-detail',
      query: {
        order_id: _vm.target_id
      }
    }}},[(_vm.state === '1' && _vm.type === '6')?_c('div',{staticClass:"btn"},[_vm._v("预约肠镜")]):_vm._e()]),_c('router-link',{attrs:{"to":{
      path: '/registration-record'
    }}},[(_vm.state === '1' && (_vm.type !== '2' && _vm.type !== '6'))?_c('div',{staticClass:"btn"},[_vm._v("我的挂号")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }