<template>
  <div class="g_page_box">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <img v-if="state === '1'" src="../../assets/img/pay_success.png" class="icon" width="153">
      <img v-else src="../../assets/img/pay_cancel.png" class="icon" width="153">
      <div class="title">{{state === '1' ? '支付成功':'支付失败'}}</div>
      <div class="surplus_time">{{state === '1'? type==='2'?'请点击查看订单进行预约':type==='6'?'请点击预约肠镜':'请点击查看挂号列表':'请重新下单'}}</div>
    </div>
    <div class="bottom_box">
      <router-link :to="{
        path: '/my-check-detail',
        query: {
          order_id: reset_query(id)
        }
      }">
        <div v-if="state === '1' && type === '2'" class="btn" >预约检查</div>
      </router-link>
      <router-link :to="{
        path: '/my-check-detail',
        query: {
          order_id: target_id
        }
      }">
        <div v-if="state === '1' && type === '6'" class="btn" >预约肠镜</div>
      </router-link>
      <router-link :to="{
        path: '/registration-record'
      }">
        <div v-if="state === '1' && (type !== '2' && type !== '6')" class="btn">我的挂号</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {post_list} from './service'
export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.orderId,
      state: this.$route.query.state,
      type: this.$route.query.type,
      target_id: this.$route.query.target_id
    }
  },
  computed: {
  },
  components: {
  },
  created () {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        await post_list({
          order_no: this.id,
          type: parseInt(this.type)
        })
      } finally {
        this.loading = false
      }
    },
    reset_query(query) {
      const arr = query.split('!!!')
      return `${arr[0]}!!!${arr[1]}`
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  text-align: center;
}
.icon {
  margin-top: 65px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #242938;
  margin-top: 28px;
}
.surplus_time {
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  margin-top: 8px;
  & label {
    color: #0088FF;
  }
}
.bottom_box {
  padding: 24px 13px;
  & .btn {
    width: 100%;
    background: #0088FF;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;

    &.s2 {
      margin-bottom: 10px;
      background: #fff;
      color: #0088ff;
      border: solid 1px #0088ff;
    }
  }
}
</style>
